













import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
    name: "InfoSection",
    props: {
        isMobile : Boolean
    },
    setup() {
        const isTablet = computed(() => {
            return window.innerWidth > 600 && window.innerWidth < 1264;
        });
        return {
            isTablet,
        }
    },
})
