<template>
    <v-card class="nft-section mt-n4 align-center d-flex flex-column" :class="isMobile ? 'mobile' : ''" color="#060726" tile>
        <v-card-title class="nft-section__title text-uppercase text-center" style="font-size: 40px; line-height: 40px; color:#BBFF71;">Start With Nft Today</v-card-title>
        <v-card-subtitle class="nft-section__subtitle align-center mt-9" :class="!isMobile ? 'desktop' : ''" style="color:white;">and build up a rich token economy,<br>or do a deep integration on day one</v-card-subtitle>
        <v-row class="justify-center nft-section__row nft-row" :class="!isMobile ? 'desktop' : ''" no-gutters>
            <v-col cols="12">
                <v-row>
                    <v-col :class="isMobile ? 'justify-center d-flex' : ''" md="4"><NftCard :cardTitle="'Game Developers'" :cardDescription="'Gaming item market and API for integration'" :imageSrc="'game-developers'"/></v-col>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16 mt-sm-0' : ''" md="4"><NftCard :cardTitle="'Online Courses'" :cardDescription="'Student’s certificates'" :imageSrc="'online-course'"/></v-col>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16 mt-sm-0' : ''" md="4"><NftCard :cardTitle="'Artists and Celebrities'" :cardDescription="'Memorable objects such as clothes and rare personal items'" :imageSrc="'artist-and-celeb'"/></v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="justify-center mt-md-16 nft-row" no-gutters>
            <v-col cols="12">
                <v-row>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16' : ''" md="4"><NftCard :cardTitle="'Patent Organizations'" :cardDescription="'Unique patent certificates in public blockchain'" :imageSrc="'patent-org'"/></v-col>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16' : ''" md="4"><NftCard :cardTitle="'Publisher and Content Creator'" :cardDescription="'Content autor licence'" :imageSrc="'publisher'"/></v-col>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16' : ''" md="4"><NftCard :cardTitle="'Event Organizations'" :cardDescription="'NFT as a tickets, posters, merchandise'" :imageSrc="'event-org'"/></v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="justify-center mt-md-16 nft-row" no-gutters>
            <v-col cols="12">
                <v-row>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16' : ''" md="4"><NftCard :cardTitle="'E-sport'" :cardDescription="'Tickets, certificates and memorable cards'" :imageSrc="'e-sport'"/></v-col>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16' : ''" md="4"><NftCard :cardTitle="'Sport'" :cardDescription="'Sportsman or team branded marketplace'" :imageSrc="'sport'"/></v-col>
                    <v-col :class="isMobile ? 'justify-center d-flex mt-16' : ''" md="4"><NftCard :cardTitle="'Auction'" :cardDescription="'Art and numismatics auction company'" :imageSrc="'auction'"/></v-col>
                </v-row>
            </v-col>
        </v-row>
        <img class="nft-section__effect" style="user-select:none" src="@/assets/img/expertise/expertise_bg_effect.png"/>
    </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import NftCard from '@/components/NftSection/NftCard.vue'

export default defineComponent({
    name: 'NftCardSection',
    components: {
        NftCard
    },
    props: {
        isMobile: Boolean
    },
    setup() {
        
    },
})
</script>

<style scoped lang="scss">
    .nft-section {
        padding: 205px 0 332px;

        &.mobile {
            padding: 111px 0 162px;
        }
        
        &__title {
            word-break: normal;
        }

        &__row {
            margin-top: 142px;

            &.desktop {
                margin-top: 175px;
            }
        }

        &__subtitle {
            font-size: 28px;
            line-height: 30px;
            max-width: 550px;
            text-align: center;
        }

        &__effect {
            position: absolute;
            bottom: 0;
            transform: rotate(180deg);
        }

        .nft-row {
            z-index: 1;
        }
    }
</style>
