import Vue from 'vue';
import VueCompositionApi from "@vue/composition-api";
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
//@ts-ignore
import AOS from 'aos'
import 'aos/dist/aos.css'


Vue.use(VueCompositionApi);
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  vuetify,
  created () {
    AOS.init()
  },
  render: (h) => h(App),
}).$mount('#app');
