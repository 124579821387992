


























































































import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'VideoHeader',
  props: {
    headerStyle: String,
  },

  setup() {
    const isDesktop = ref(false);

    function checkDesktop() {
      if (screen.width < 1200 && window.innerWidth < 1200) {
        isDesktop.value = false;
      } else {
        isDesktop.value = true;
      }
    }

    onMounted(() => {
      checkDesktop();
    });

    return {
      isDesktop,
      checkDesktop,
    };
  },
});
