


















































































import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "ClientSection",
  props: {
    isMobile: Boolean
  },
  setup() {
    const isTablet = computed(() => window.innerWidth > 600 && window.innerWidth < 1264);
    return {
      isTablet
    };
  }
});
