






import { defineComponent } from '@vue/composition-api'
import ClientCard from '@/components/ClientsSection/ClientCard.vue'

export default defineComponent({
    name: "ClientCardSection",
    props: {
        isMobile: Boolean
    },
    components: {
        ClientCard
    },
    setup() {
        
    },
})
