



























import { defineComponent } from '@vue/composition-api';
import ProductCard from '@/components/ProductSection/ProductCard.vue';

export default defineComponent({
    name: 'ProductCardSection',
    components: {
        ProductCard
    },
    props: {
    },
    setup() {
        return {
        };
    }
});

