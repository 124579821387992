<template>
    <v-card class="blockchain-section" :class="!isMobile ? 'desktop' : ''" tile elevation="0">
        <v-card-title class="blockchain-section__title justify-center mb-7">Blockchain We Build On</v-card-title>
        <v-row class="justify-center" no-gutters>
            <v-col cols="11" md="12" lg="10">
                <v-row no-gutters>
                    <v-col cols="5" sm="6" lg="2" class="blockchain-section__img--wrapper justify-sm-center mb-4">
                        <v-img
                            src="@/assets/img/blockchain/Ethereum.png"
                            class="blockchain-section__img ether"
                        />
                    </v-col>
                    <v-col cols="7" sm="6" lg="2" class="blockchain-section__img--wrapper justify-end justify-sm-center mb-4">
                        <v-img
                            src="@/assets/img/blockchain/Binance.png"
                            class="blockchain-section__img binance"
                        />
                    </v-col>
                    <v-col cols="6" lg="2" class="blockchain-section__img--wrapper justify-sm-center mb-4">
                        <v-img
                            src="@/assets/img/blockchain/Tezos.png"
                            class="blockchain-section__img tezos"
                        />
                    </v-col>
                    <v-col cols="6" lg="2" class="blockchain-section__img--wrapper justify-end justify-sm-center mb-4">
                        <v-img
                            src="@/assets/img/blockchain/Cardano.png"
                            class="blockchain-section__img cardano"
                        />
                    </v-col>
                    <v-col cols="6" lg="2" class="blockchain-section__img--wrapper justify-sm-center">
                        <v-img
                            src="@/assets/img/blockchain/flow.png"
                            class="blockchain-section__img flow"
                        />
                    </v-col>
                    <v-col cols="6" lg="2" class="blockchain-section__img--wrapper justify-center">
                        <v-img
                            src="@/assets/img/blockchain/diam.png"
                            class="blockchain-section__img diam"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: "BlockChainSection",
    props: {
        isMobile: Boolean
    },
    setup() {
    },
})
</script>

<style scoped lang="scss">
    .blockchain-section {
        background: linear-gradient(90deg, rgba(22, 211, 243, 0.36) 5.53%, rgba(130, 9, 202, 0.36) 100%);
        padding: 32px 0 70px 0;

        &__title {
            color: #C9CAF7;
            font-size: 22px;
            line-height: 28px;
            text-transform: uppercase;
        }

        &__img {
            &--wrapper {
                height: 65px;
                position: relative;
                display: flex;
                align-items: center;
            }

            &.ether {
                height: 48px;
                max-width: 123px;
            }
            &.binance {
                height: 43px;
                max-width: 173px;
            }
            &.tezos {
                height: 45px;
                max-width: 131px;
            }
            &.cardano {
                max-width: 180px;
            }
            &.flow {
                height: 52px;
                max-width: 124px;
            }
            &.diam {
                height: 54px;
                max-width: 54px;
            }
        }
    }
</style>
