













import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'ProductCard',
    props: {
        imageSrc: String,
        title: String,
    },
    setup() {
        const isTablet = computed(() => {
            return window.innerWidth > 600 && window.innerWidth < 961;
        });
        return {
            isTablet,
        }
    }
})
