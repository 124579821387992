







import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  components: {
  },
  
  data: () => ({
  }),
  
  methods: {
    isMobile: function() {
      return window.innerWidth < 600;
    }
  }
});
