<template>
  <v-card class="partners-section" tile elevation="0">
    <v-card-title class="partners-section__title justify-center">Business Partners</v-card-title>
    <v-row class="justify-center">
      <v-col cols="12" lg="8">
        <v-row class="justify-center align-center">
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/altlift.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/amber.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/animoca.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/anchor.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/binance.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/tatum.svg" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/bizztech.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/luna.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img
              src="@/assets/img/partners/blockhype.png"
              contain
              class="partners-section__img"
            />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/polygon.png" contain class="partners-section__img" />
          </v-col>
          <v-col cols="4" lg="2" class="partners-section__img-wrapper">
            <v-img src="@/assets/img/partners/ralba.png" contain class="partners-section__img" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "PartnersSection",
  props: {},
  setup() {}
});
</script>

<style scoped lang="scss">
.partners-section {
  background: #2e1263;
  padding: 48px 10px;

  &__title {
    color: #c9caf7;
    font-size: 22px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  &__img-wrapper {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 20px;

    @media only screen and (max-width: 375px) {
      margin: 0 10px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
