<template>
   <div>
      <v-card class="contact-form" :class="{'desktop': !isMobile}" elevation="0" id="contact">
        <img class="contact-form__effect" style="user-select:none" src="@/assets/img/contact-img.png"/>
        <v-card-title class="contact-form__title text-uppercase justify-center">
            <img class="mr-7" src="@/assets/img/diamond.png"></img>
            <span>Contact Us</span>
        </v-card-title>
        <form class="contact-form__form d-flex justify-center" @submit.prevent="submit">
            <div class="contact-form__form-wrapper">
                <div class="contact-form__input input-name mb-5">
                    <input type="text" name="name" id="name" required="" placeholder="Name" v-model="name"
                    :class="{'wrong-name': !nameValid}"
                    v-on:blur="lostFocus"
                >
                </div>
                <div class="error-message">
                    <p v-show="!nameValid">Please enter Your name.</p>
                </div>
                <div class="contact-form__input input-email mb-5">
                    <input type="email" name="email" id="email" placeholder="E-mail" required=""
                        :class="{'wrong-email': !emailValid}"
                        v-model="email"
                        v-on:blur="lostFocus"
                    >
                </div>
                <div class="error-message">
                    <p v-show="!emailValid">Please enter a valid email address.</p>
                </div>
                <div class="contact-form__input input-message">
                    <textarea type="text" name="message" id="message" required="" :class="{'wrong-message': !messageValid}" placeholder="Message - active" v-model="message"></textarea>
                </div>
                <div class="error-message">
                    <p v-show="!messageValid">Message field can not be empty.</p>
                </div>
                <div class="contact-form__btn-wrapper">
                    <a class="contact-form__btn" @click="submit">
                            Submit
                    </a>
                </div>
            </div>
        </form>
        <SocialMedia :isMobile="isMobile" />
    </v-card>
    <section class="popup" v-if="showPopupSuccess">
       <img
            src="@/assets/xicon.png"
            class="x-icon"
            @click="closePopup"
          />
      <figure class="imageWrapper">
         <img
            src="@/assets/paperplane2.png"
          />
      </figure>
      <h1>Wohoo!</h1>
      <p>Your message was sent!</p>
    </section>
       <section class="popup" v-if="showPopupFailed">
       <img
            src="@/assets/xicon.png"
            class="x-icon"
            @click="closePopup"
          />
      <figure class="imageWrapper">
         <img
            src="@/assets/paperplane_fall2.png"
          />
      </figure>
      <h1>Ah..</h1>
      <p>Some error occured. Keep calm and try again.</p>
    </section>
   </div>
</template>

<script>
import {
  defineComponent, ref, watch,
} from '@vue/composition-api';
import SocialMedia from '@/components/SocialMedia.vue';

export default defineComponent({
  name: 'ContactForm',
  props: {
    isMobile: Boolean,
  },
  components: {
    SocialMedia,
  },
  setup() {
    const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const name = ref('');
    const nameValid = ref(true);
    const email = ref('');
    const emailValid = ref(true);
    const message = ref('');
    const messageValid = ref(true);
    const showPopupSuccess = ref(false);
    const showPopupFailed = ref(false);

    async function submit() {
      if (name.value === '') {
        nameValid.value = false;
      } else if (message.value === '') {
        messageValid.value = false;
      } else if (email.value === '') {
        emailValid.value = false;
      } else if (!isEmailValid(email.value)) {
        emailValid.value = false;
      } else {
        try {
          const res = await fetch('https://hook.integromat.com/udrwqdqrlckjy1v8nk4j8wtlop5eofej', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
              name: name.value,
              email: email.value,
              message: message.value,
            }),
          });

          if (res.ok) {
            showPopupFailed.value = false;
            showPopupSuccess.value = true;
          } else {
            showPopupSuccess.value = false;
            showPopupFailed.value = true;
          }
        } catch {
          showPopupSuccess.value = false;
          showPopupFailed.value = true;
        }

        setTimeout(() => {
          email.value = '';
          name.value = '';
          message.value = '';
        }, 1000);
      }
    }

    function closePopup() {
      showPopupSuccess.value = false;
      showPopupFailed.value = false;
    }

    function isEmailValid(emailValue) {
      return emailRegExp.test(emailValue);
    }

    watch(email, (val) => {
      emailValid.value = true;
      document.getElementById('email').classList.remove('wrong-email');
    });

    watch(name, (val) => {
      nameValid.value = true;
      document.getElementById('name').classList.remove('wrong-name');
    });

    watch(message, (val) => {
      messageValid.value = true;
      document.getElementById('message').classList.remove('wrong-message');
    });

    function lostFocus() {
      document.getElementById('email').classList.remove('wrong-email');
      document.getElementById('name').classList.remove('wrong-name');
      nameValid.value = true;
      emailValid.value = true;
    }

    return {
      email,
      emailValid,
      name,
      nameValid,
      message,
      showPopupSuccess,
      showPopupFailed,
      messageValid,
      submit,
      closePopup,
      lostFocus,
    };
  },
});
</script>

<style scoped lang="scss">
  .imageWrapper {
    height: 200px;
    width: 100%;
    margin: 40px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 140px;
      width: 140px;
    }

  }

  .x-icon {
    z-index: 10;
    height: 30px;
    width: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .popup {
    z-index: 10;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 340px;
    max-width: 500px;
    height: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

      h1 {
      font-size: 32px;
    }
  }

    .contact-form {
        background: #060726;
        padding: 161px 0 93px;

        &__form-wrapper {
            z-index: 2;
        }

        &__effect {
            display: none;
            .desktop & {
                position: absolute;
                z-index: 0;
                bottom: 0;
                display: block;
                user-select: none;
            }
        }

        &__input {
            input,
            textarea {
                border: 2px solid #BBFF71;
                border-radius: 4px;
                padding: 26px 30px;
                font-size: 24px;
                line-height: 28px;
                width: 374px;
                color: #bbff71;

                &:focus::placeholder {
                    color: transparent;
                }

                &::placeholder {
                    font-size: 24px;
                    line-height: 28px;
                    color: #bbff71;
                }

                .desktop & {
                    width: 400px;
                }
            }

            &.input-message {
                textarea {
                    min-height: 200px;

                    &.wrong-message {
                        border-color: #ef4545;
                        color: #ef4545;

                        &::placeholder {
                            color: #ef4545;
                        }
                    }
                }
            }

            input {
                &.wrong-email,
                &.wrong-name {
                    border-color: #ef4545;
                    color: #ef4545;

                    &::placeholder {
                        color: #ef4545;
                    }
                }
            }
        }

        &__title {
            color: white;
            font-size: 40px;
            line-height: 40px;
            position: relative;
            margin-bottom: 121px;
        }

        &__btn {
            padding: 19px;
            text-transform: uppercase;
            font-size: 22px;
            line-height: 22px;
            background: #BBFF71;
            border-radius: 4px;
            text-decoration: none;
            color: #060726;
            font-weight: 700;
            width: 100%;
            text-align: center;

            &-wrapper {
                display: flex;
                margin-top: 63px;
            }
        }

        .error-message {
            color: #ef4545;
        }
    }
</style>
