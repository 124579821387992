




















import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ExpertiseCard",
  props: {
    imageSrc: String,
    title: String,
    text: String,
    isMobile: Boolean
  },
  setup() {
    return {};
  }
});
