<template>
    <v-card class="expertise-section" :class="!isMobile ? 'desktop' : ''" color="#060726" elevation="0" tile>
        <img class="expertise-section__effect" style="user-select:none" src="@/assets/img/expertise/expertise_bg_effect.png"/>
        <v-card-title class="expertise-section__title text-uppercase justify-center">
            <img class="mr-7" src="@/assets/img/diamond.png"></img>
            <span>EXPERTISE</span>
        </v-card-title>
        <v-row class="justify-center expertise-section__row" :class="!isMobile ? 'desktop' : ''" no-gutters>
            <v-col cols="8" sm="10" lg="8">
                <v-row class="justify-center">
                    <v-col class="text-left" md="6">
                        <ExpertiseCard :isMobile="isMobile" :imageSrc="'marketplace'" :text="'Building your own multi-chain NFT marketplace with back-office for handling items.'" :title="'NFT MARKETPLACE'"/>
                    </v-col>
                    <v-col class="text-left" md="6">
                        <ExpertiseCard :isMobile="isMobile" :imageSrc="'ecosystem'" :text="'Building complete crypto ecosystem solutions based on blockchain.'" :title="'Crypto Ecosystem'"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="justify-center mt-10" no-gutters>
            <v-col cols="8" sm="10" lg="8">
                <v-row class="justify-center">
                    <v-col class="text-left" md="6">
                        <ExpertiseCard :isMobile="isMobile" :imageSrc="'blockchain-integration'" :text="'Implementation Blockchain technology into your application or ecosystem.'" :title="'Blockchain integration'"/>
                    </v-col>
                    <v-col class="text-left" md="6">
                        <ExpertiseCard :isMobile="isMobile" :imageSrc="'smart-contract'" :text="'Custom Smart Contracts for your specific use case'" :title="'Custom Smart Contracts'"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import ExpertiseCard from '@/components/ExpertiseSection/ExpertiseCard.vue'

export default defineComponent({
    name: 'NftCardSection',
    props: {
        isMobile: Boolean
    },
    components: {
        ExpertiseCard
    },
    setup() {
        
    },
})
</script>

<style scoped lang="scss">
    .expertise-section {
        padding: 113px 0;

        &.desktop {
            padding: 207px 0;
        }

        &__row {
            margin-top: 50px;
            
            &.desktop {
                margin-top: 90px;
            }
        }

        &__title {
            color: white;
            font-size: 40px;
            line-height: 40px;
        }

        &__effect {
            position: absolute;
            top: 0;
        }
    }

    ::v-deep .mobile--true {
        margin-top: 59px;
    }

</style>