<template>
  <v-row
    id="contact"
    class="social-media__section justify-center"
    :class="isMobile ? 'mobile' : 'pt-10 pb-10'"
    no-gutters
  >
    <v-col cols="8" md="10" lg="8" class="social-media__wrapper">
      <v-row class="align-center" :class="isMobile ? 'flex-column' : ''" no-gutters>
        <v-col
          align-self="start"
          :class="isMobile ? 'd-flex flex-column flex-md-row align-center order-3 mt-16' : ''"
        >
          <img src="@/assets/img/social-media/logo.png" />
          <span v-if="isMobile">2022 @ BlockCzech</span>
        </v-col>
        <v-col class="d-flex flex-column align-center" :class="isMobile ? 'order-2 mt-15' : ''">
          <v-row class="mb-11" no-gutters>
            <v-col>
              <a href="https://discord.gg/pW3DpP4TXZ" target="_blank">
                <v-img
                  class="social-media__icon mr-5"
                  src="@/assets/img/social-media/discord.png"
                ></v-img>
              </a>
            </v-col>
            <v-col>
              <a href="https://www.instagram.com/blockczech/" target="_blank">
                <v-img
                  class="social-media__icon mr-5"
                  src="@/assets/img/social-media/instagram.png"
                ></v-img>
              </a>
            </v-col>
            <v-col>
              <a href="https://www.linkedin.com/company/blockczech/" target="_blank">
                <v-img
                  class="social-media__icon"
                  src="@/assets/img/social-media/linkedin.png"
                ></v-img>
              </a>
            </v-col>
          </v-row>
          <span v-if="!isMobile">2022 @ BlockCzech</span>
        </v-col>
        <v-col
          align-self="start"
          class="d-flex flex-column align-center"
          :class="isMobile ? 'order-1' : 'align-end'"
        >
          <v-row no-gutters>
            <v-col class="pa-0 mb-2">
              <a class="email-link" href="mailto: info@blockczech.io">
                <span>info@blockczech.io</span>
              </a>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="ma-0 pa-0">
              <a class="phone-link" href="tel:+496170961709">
                <span class="white--text"> +420 777 853 835</span>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script type="ts">
export default {
  name: 'SocialMedia',
  props: {
    isMobile: Boolean,
  },
  setup() {
  },
};
</script>

<style scoped lang="scss">
.social-media {
  &__section {
    background: rgba(0, 0, 0, 0.37);
    margin-top: 193px !important;

    &.mobile {
      padding: 94px 0 68px;
      margin-top: 93px !important;
    }

    a {
      text-decoration: none;
    }
    .email-link,
    .phone-link {
      display: flex;
      color: unset;
      font-size: 30px;
      line-height: 36px;
      &:hover {
        text-decoration: none;
      }
    }

    .email-link {
      span {
        color: #16d3f3;
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;
      }
    }

    .phone-link {
      font-size: 16px;
      line-height: 16px;
    }
    span {
      color: white;
    }
  }

  &__icon {
    height: 50px;
    width: 50px;
    margin: auto;
  }

  &__wrapper {
    z-index: 1;
  }
}
</style>
