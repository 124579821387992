























import { Component, Vue } from "vue-property-decorator";
// @ts-ignore
import SocialMedia from "@/components/SocialMedia.vue";
import NavBarHam from "@/components/NavBar/NavBarHam.vue";
import ProductCardSection from "@/components/ProductSection/ProductCardSection.vue";
import NftCardSection from "@/components/NftSection/NftCardSection.vue";
import VideoHeader from "@/components/VideoHeader.vue";
import ExpertiseCardSection from "@/components/ExpertiseSection/ExpertiseCardSection.vue";
import InfoSection from "@/components/InfoSection.vue";
import BlockChainSection from "@/components/Blockchain/BlockchainSection.vue";
import MeetTeamSection from "@/components/MeetTeamSection.vue";
import PartnersSection from "@/components/Blockchain/PartnersSection.vue";
import ClientsSection from "@/components/ClientsSection/ClienCardSection.vue";
import ContactForm from "@/components/ContactForm.vue";
// import ContactForm from '@/components/ContactForm.vue'

@Component({
  components: {
    SocialMedia,
    ProductCardSection,
    NftCardSection,
    VideoHeader,
    ExpertiseCardSection,
    InfoSection,
    BlockChainSection,
    MeetTeamSection,
    PartnersSection,
    NavBarHam,
    ClientsSection,
    ContactForm
  }
})
export default class Home extends Vue {
  currentSection = 1;

  scrollPosition = 0;

  ticking = false;

  isDesktop = true;

  options = {
    duration: 300,
    offset: 0,
    easing: "easeInOutCubic"
  };

  isMobile() {
    return window.innerWidth < 960;
  }

  checkDesktop() {
    if (screen.width < 1200 && window.innerWidth < 1200) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
  }
  // isTablet() {
  //   return window.innerWidth > 600 && window.innerWidth < 961;
  // }

  mounted() {
    this.checkDesktop();
  }

  getVideoHeaderStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case "md":
      case "lg":
      case "xl":
        return "font-size:63px;line-height:74px;bottom:20%;left:15%;";
    }
  }
}
