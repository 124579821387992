


















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'NftCard',
    props: {    
        imageSrc: String,
        cardTitle: String,
        cardDescription: String
    },
    setup() {
        
    },
})
