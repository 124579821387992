
































import { defineComponent, ref, onMounted, onUnmounted } from '@vue/composition-api';

export default defineComponent({
    name: "NavBarHam",
    props: {
        isMobile: Boolean
    },
	setup() {
        const showHeader = ref(true);
        const lastScrollPosition = ref(0);
        const scrollOffset = ref(120);
		
        function togggleBtn() {
            var myLinksElem = document.getElementById("myLinks");
            if(myLinksElem) {
                if (myLinksElem.style.display === "block") {
                    myLinksElem.style.display = "none";
                } else {
                    myLinksElem.style.display = "block";
                }
            }
        }
        function closeHamNav() {
            var myLinksElem = document.getElementById("myLinks");
            if(myLinksElem) {
                myLinksElem.style.display = "none";
            }
        }

        function onScroll() {
            if (window.pageYOffset < 0) {
                return;
            }
            if (Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value) {
                return;
            }

            showHeader.value = window.pageYOffset < lastScrollPosition.value;
            lastScrollPosition.value = window.pageYOffset;
        }

        onMounted(() => {
            lastScrollPosition.value = window.pageYOffset;
            window.addEventListener('scroll', onScroll);
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', onScroll);
        })

        return {
            togggleBtn,
            closeHamNav,
            showHeader,          
        }

	}
})
