<template>
    <v-card id="team" class="team-section" :class="isMobile ? 'mobile' : ''" color="#060726" elevation="0" tile>
        <div>{{check}}</div>
        <img class="team-section__effect" style="user-select:none" src="@/assets/img/bg-effect-team.png"/>
        <v-card-title class="team-section__title text-uppercase justify-center">
            <img class="mr-7" src="@/assets/img/diamond.png"></img>
            <span>Meet The Team</span>
        </v-card-title>
        <v-row class="justify-center team-section__row" no-gutters>
            <v-col cols="10">
                <v-row class="justify-center flex-column flex-md-row">
                    <v-col class="team-section__img" lg="4">
                        <img style="user-select:none" src="@/assets/img/team/Jakub.jpg"/>
                        <span>JAKUB STRNAD</span>
                        <span class="position">CTO</span>
                    </v-col>
                    <v-col :class="isMobile ? 'mt-13' : ''" class="team-section__img" lg="4">
                        <img style="user-select:none" src="@/assets/img/team/Adam.jpg"/>
                        <span>ADAM ZELFEL</span>
                        <span class="position">CEO</span>
                    </v-col>
                    <v-col :class="isMobile ? 'mt-13' : ''" class="team-section__img" lg="4">
                        <img style="user-select:none" src="@/assets/img/team/Artem.jpg"/>
                        <span>ARTEM GRIBANOV</span>
                        <span class="position">CMO</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'MeetTeamSection',
    props: {
        isMobile : Boolean
    },
    setup(props) {
        const check = computed (() => {
            if(props.isMobile)
            {
                return 5;
            }
        });
        return {
            check
        }
    },
})
</script>

<style scoped lang="scss">
    .team-section {
        padding: 207px 0;
        border-top: 1px solid #BBFF71;
        border-color: #BBFF71;
        &.mobile {
            padding: 127px 0 150px;

            span:not(.position){
                font-size: 32px;
                line-height: 32px;
            }

            .position {
                margin-top: 24px;
            }

            .team-section__effect {
                object-fit: cover;
            }

            .team-section__img {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &__row {
            margin-top: 90px;
            position: relative;
        }

        &__title {
            color: white;
            font-size: 40px;
            line-height: 40px;
            position: relative;
        }

        &__effect {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
        &__img {
            text-align: center;

            img {
                width: 360px;
                height: 360px;
                margin-bottom: 46px;
            }
            span {
                display: block;
                color: white;
                text-transform: uppercase;
                font-weight: 700;

                &.position {
                    color: #BBFF71;
                    font-weight: normal;
                    font-size: 12px;
                }
            }
        }   
    }
</style>